import { globalStatus } from '@/provide/global-contract'
import { message } from 'ant-design-vue'
import { toPng } from 'html-to-image'
import { Observable, of, Subject } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { fontFamilyManager } from '../fonts/font-family-manager'
import { PageSnapshootsResult } from './archive-contract'


export class ExportImageService {


  private _exportResultGenerated = new Subject<PageSnapshootsResult[]>();

  get exportResultGenerated(): Observable<PageSnapshootsResult[]> {
    return this._exportResultGenerated.asObservable()
  }

  /**
   * 通知订阅者图片导出结果
   * @param _result 
   * @returns 
   */
  notifyExportResultGenerated(_result: PageSnapshootsResult[]) {
    this._exportResultGenerated.next(_result)
    globalStatus.exportingImage = false
  }


  /**
   * 执行导出页面快照流程
   */
  executeExportPageSnapshoots() {
    globalStatus.exportingImage = true
  }


  /**
   * 获取图片的DataURL值
   * @param domRef 
   * @param quality 
   * @param ignoreWebfont 
   * @param callback 
   */
  getPngImageDataURLBy(
    domRef: HTMLElement,
    quality: number,
    width: number,
    callback: (dataURL: string) => void,
    onerror?: (error: any) => void) {
    if (!domRef) {
      throw new Error('getPngImageDataURLBy,but domRef is null.')
    }
    const foreignObjectSpans = domRef.querySelectorAll('foreignObject [xmlns]')
    foreignObjectSpans.forEach(spanRef => spanRef.removeAttribute('xmlns'))

    fontFamilyManager.fetchWebFontEmbedCss(domRef)
      .pipe(
        tap(
          _fontEmbedCSS => {
            // console.log('fetchWebFontEmbedCss:', _fontEmbedCSS)
            toPng(domRef, {
              quality,
              width,
              // TAG：`fontEmbedCSS:''`表示在导出时不支持文件内容中引用的网络字体，如果不设置则表示在导出时支持文件内容中引用的网络字体
              fontEmbedCSS: _fontEmbedCSS
            }).then(dataUrl => {
              console.log('dataUrl:', dataUrl?.length)
              callback?.(dataUrl)
            }).catch((error) => {
              console.error('getPngImageDataURLBy error:', error)
              onerror?.(error)
            })
          }
        )
      ).subscribe()

  }

}

export const _exportImageService = new ExportImageService()