import { Slide } from '@/types/slides'
import { reactive } from 'vue'
export const globalStatus = reactive({
// /**
//  * 保存状态
//  */
//   saveLoading: false,
  /**
   * 是否正在导出图片
   */
  exportingImage: false,
  /**
   * 数据是否加载完成
   */
  loaded: false
})

export const globalData = reactive({
  /**
   * 文件名称
   */
  name: '',
  /**
   * 编辑器dom
   */
  port: document.getElementById('jk-ppt-port'),
  /**
   * ppt数据
   */
  slides: [] as Slide[]
})

