/* eslint-disable no-console */
import { useSlidesStore } from '@/store'
import { message } from 'ant-design-vue'
import { toJpeg, toPng } from 'html-to-image'
import { ExportImageConfig } from './useExport'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { _pptLocalStorageService } from '@/data/archive/local-storage'
import { _iframeDriver } from '@/data/archive/iframe-driver'
import { _pptArchiveHelper } from '@/data/archive/archive-helper'
import { Slide } from '@/types/slides'



export default () => {

  const slidesStore = useSlidesStore()
  const { allSlides } = storeToRefs(slidesStore)
  const exporting = ref(false)

  /**
   * 获取slides
   * @returns 
   */
  const getSlides = () => {
    return allSlides.value
  }

  /**
   * 设置slides
   * @returns 
   */
  const setSlides = (_value:Slide[]) => {
    slidesStore.setSlides(_value)
  }

  /**
   * 定于Slides[]数据被更新时的事件
   * @param callback 
   */
  const subscribeSlidesChanges = (callback: (...args: any[]) => void) => {
    slidesStore.$subscribe((mutation, state) => {
      callback(mutation, state)
    })
  }

  return {
    getSlides,
    setSlides,
    subscribeSlidesChanges
  }
}