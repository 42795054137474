// TGA:定义了一些环境变量

export class EnvContract {

  /**
   * library website domain for local debug
   */
  static LIBRARY_WEB_SITE_DOMAIN_LOCAL_HOST = "http://localhost:9100";

  /**
   * library website domain for local server
   */
  static LIBRARY_WEB_SITE_DOMAIN_LOCAL = "http://192.168.22.243:8089";

  /**
   * library website domain for dev server
   */
  static LIBRARY_WEB_SITE_DOMAIN_DEV = "https://dev.api.yunjingxz.com";

  /**
   * library website domain for prod server
   */
  // static LIBRARY_WEB_SITE_DOMAIN_PROD = "https://api.yunjingxz.com";
  static LIBRARY_WEB_SITE_DOMAIN_PROD = "https://api.drawon.cn";

  /**
   * 静态文件cos服务器地址
   */
  static LIBRARY_WEB_SITE_DOMAIN_STATIC_COS = "https://static.cos.drawon.cn";


}
