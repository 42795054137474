import { UrlUtils, ObjectUtils } from 'jkf-pure-commons'
import { RunningContext } from './contract'

/**
 * 用于解析当前的urlParams中参数
 */
export class UrlParamsResolver {

    private _context: RunningContext | undefined;

    private _viewModeEnabled: boolean = false;

    private _isIntegratedLayout: boolean = false;

    /**
     * 当前运行的环境
     */
    get context(): RunningContext | undefined {
        return this._context
    }

    /**
     * 当前是否为查看模式
     */
    get viewModeEnabled(): boolean {
        return this._viewModeEnabled
    }

    /**
    * 是否以融合方式进行布局
    */
    get isIntegratedLayout(): boolean {
        return this._isIntegratedLayout
    }


    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {
        this.analysis()
    }

    /**
     * 从url中解析环境变量
     */
    analysis() {
        const obj = UrlUtils.analysisPathAndQueryParamsFragments(window.location.href)
        if (obj && obj.queryParams) {
            this._context = +ObjectUtils.safelyGetObjValue(obj.queryParams, 'context') as RunningContext
            const viewMode = ObjectUtils.safelyGetObjValue(obj.queryParams, 'viewMode')
            if (ObjectUtils.isNotNull(viewMode)) {
                this._viewModeEnabled = +viewMode === 1
            }
            const _integratedLayout = obj.queryParams['integratedLayout']
            if (ObjectUtils.isNotNull(_integratedLayout)) {
                this._isIntegratedLayout = +_integratedLayout === 1
            }
        }
    }

}

export const _urlParamsResolver = new UrlParamsResolver()
