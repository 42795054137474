// 1-based in case we ever do `if(element.fontFamily)`
import { CollectionUtils, CssUtils, JkKeyValueBean, StringUtils } from "jkf-pure-commons"
import { Observable, of, zip } from "rxjs"
import { map } from "rxjs/operators"

export interface FontFamilyBean extends JkKeyValueBean {
    /**
     * 字体资源链接
     */
    url?: string;
}


/**
 * 数据源的类型。
 */
export enum JkSourceType {
    /**
       * source 指向的是一个的path.
       */
    LINK = 'LINK',

    /**
     * 通过对象存储上传，source 指向的是一个内置的对象存储的path.
     */
    COS = 'COS'
}

/**
 *
 * 开放库的分类，只有drawon-admin可以创建该数据。
 */

export interface JkSysFont {
    _id: string;
    /**
     * 数据源的类型. 一种是通过直传到对象存储，一种是用户自己的链接。
     */
    sourceType: JkSourceType;

    /**
     *
     * 就是一个链接的path.
     */
    source: string;

    /**
     * 类别名称
     */
    name: string;

    /**
     * 描述
     */
    description: string;

    /**
     * 启用禁用
     */
    enabled: boolean;
    creatorId: string;
    updatedAt: number;
    createdAt: number;
}

export class FontFamilyUtils {


    /**
     * 通过`FontFamilyBean[]`拼接指定的网络字体资源的`@fontface`字符串
     */
    static jointFontfacesStringFrom(_fbeans: FontFamilyBean[]): string {
        let styleString = ''
        if (CollectionUtils.validList(_fbeans)) {
            _fbeans.forEach(
                f => {
                    styleString += `${CssUtils.generateFontfaceString(
                        f.value,
                        f.url as any
                    )}`
                }
            )
        }
        return styleString
    }

    /**
     * 通过字体资源的外部链接url获取DataURI
     * 参考`html-to-image`中的`fetchAsDataURL()`的执行逻辑，配合`html-to-image`使用
     * @see https://github.com/bubkoo/html-to-image/blob/master/src/dataurl.ts
     * @param url 
     * @returns 
     */
    static htiFetchFontUrlAsDataURL<T>(
        url: string
    ): Observable<string> {
        return new Observable(
            _emitter => {
                fetch(url)
                    .then(
                        res => {
                            if (res.status === 404) {
                                throw new Error(`Resource "${res.url}" not found`)
                            }
                            res.blob()
                                .then(blob => {
                                    const reader = new FileReader()
                                    reader.onerror = (_err) => {
                                        _emitter.error(_err)
                                    }
                                    reader.onloadend = () => {
                                        const _format = CssUtils.getFontFileFormatByUrl(url)
                                        const _fontMIME = CssUtils.getFontMimeByFormat(_format as any)
                                        const urlContent = (reader.result as string).split(/,/)[1]
                                        _emitter.next(`data:${_fontMIME};base64,${urlContent}`)
                                        _emitter.complete()
                                    }
                                    reader.readAsDataURL(blob)
                                })
                        }
                    )

            }
        )
    }

    /**
     * 将字体资源的url转换为DataURL
     * @param _webFonts 
     * @param _finished 
     */
    static generateFontfacesString(_webFonts: FontFamilyBean[]): Observable<string> {
        if (CollectionUtils.validList(_webFonts)) {
            const obsList = _webFonts.map(
                _wf => {
                    return FontFamilyUtils.htiFetchFontUrlAsDataURL(_wf.url as any)
                        .pipe(
                            map(
                                _dataURL => {
                                    return {
                                        value: _wf.value,
                                        url: _dataURL
                                    } as FontFamilyBean
                                }
                            )
                        )
                }
            )
            return zip(...obsList)
                .pipe(
                    map(
                        _wfList => {
                            // console.log('generateFontfacesString,zip:', _wfList)
                            return FontFamilyUtils.jointFontfacesStringFrom(_wfList)
                        }
                    )
                )
        }
        return of('')
    }


    /**
     * 将`JkSysFont[]`转换为`FontFamilyBean[]`
     * @param _list 
     */
    static convertSysFontsToFontFamilyBeans(_list: JkSysFont[]): FontFamilyBean[] {
        if (CollectionUtils.validList(_list)) {
            return _list.map(
                (_l, _idx) => {
                    return {
                        value: _l.name,
                        label: _l.name,
                        url: _l.source,
                    }
                }
            )
        }
        return []
    }

}