import { SlideTheme } from '@/types/slides'

// export const theme: SlideTheme = {
//   themeColor: '#5b9bd5',
//   fontColor: '#333',
//   fontName: 'Microsoft Yahei',
//   backgroundColor: '#fff',
// }

export const theme: SlideTheme = {
  themeColor: '#e48312',
  fontColor: '#333',
  fontName: 'Microsoft Yahei',
  backgroundColor: '#fff',
}