import { message } from 'ant-design-vue'
import { throttle } from 'lodash'

/**
 * 基于`ant-design-vue`封装的消息提示框工具类
 */
export class JkzMessageUtils {

  /**
       * 带有截流功能的success消息提示框
       * @param msg 消息内容
       * @param wait 默认值为1000
       */
  static throttleSuccess(msg: string, wait = 1000) {
    console.log('throttleSuccess:', msg, wait)
    throttle(() => {
      message.success(msg)
    }, wait, { leading: true, trailing: false })()
  }

  /**
     * 带有截流功能的info消息提示框
     * @param msg 消息内容
     * @param wait 默认值为1000
     */
  static throttleInfo(msg: string, wait = 1000) {
    throttle(() => {
      message.info(msg)
    }, wait, { leading: true, trailing: false })()
  }


  /**
     * 带有截流功能的error消息提示框
     * @param msg 消息内容
     * @param wait 默认值为1000
     */
  static throttleError(msg: string, wait = 1000) {
    throttle(() => {
      message.error(msg)
    }, wait, { leading: true, trailing: false })()
  }


  /**
    * 带有截流功能的warning消息提示框
    * @param msg 消息内容
    * @param wait 默认值为1000
    */
  static throttleWarning(msg: string, wait = 1000) {
    throttle(() => {
      message.warning(msg)
    }, wait, { leading: true, trailing: false })()
  }


  /**
    * 带有截流功能的loading消息提示框
    * @param msg 消息内容
    * @param wait 默认值为1000
    */
  static throttleLoading(msg: string, wait = 1000) {
    throttle(() => {
      message.loading(msg)
    }, wait, { leading: true, trailing: false })()
  }

}