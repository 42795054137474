/* eslint-disable @typescript-eslint/no-explicit-any */
import { Slide } from '@/types/slides'

/**
 * 通过ifram加载的编辑器行为
 */
export enum EmbedEditorAction {
  // TAG:来自父级window的命令
  /**
   * 加载文件
   */
  load = 'load',
  /**
   * 插入图片请求响应
   */
  insertImgResponse = 'insertImgResponse',
  /**
   * 生成页面快照请求
   */
  pageSnapshootsRequest = 'pageSnapshootsRequest',
  /**
   * 导出为
   */
  exportAs = "exportAs",
  // TAG:发送给父级window的命令
  /**
   * ppt编辑器初始化完成
   */
  _init = 'init',

  /**
   * 自动保存
   */
  _autosave = 'autosave',
  /**
   * 保存
   */
  _save = 'save',
  /**
   * 退出
   */
  _exit = 'exit',
  /**
   * 保存并退出
   */
  _saveExit = 'saveExit',
  /**
   * 选择文件
   */
  _select = 'select',
  /**
   * 重命名
   */
  _rename = 'rename',
  /**
   * 插入图片请求
   */
  _insertImgRequest = 'insertImgRequest',
  /**
   * 页面快照请求响应
   */
  _pageSnapshootsResponse = 'pageSnapshootsResponse',
  /**
   * 将iframe移动到最高层
   */
  _iframeTopLayer = 'iframeTopLayer',
  /**
   * 将iframe移动到最底层
   */
  _iframeLowLayer = 'iframeLowLayer'
}


/**
 * 通过ifram加载的编辑器消息中data的数据结构
 */
export class EmbedEditorMsgData {
  constructor(
    /**
     * 数据
     */
    public action: EmbedEditorAction,
    /**
     * 数据结构
     */
    public content?: any,
    /**
     * 文件名称
     */
    public name?: string,
  ) {

  }
}

export interface ArchiveInfoEntity {
  /**
   * map初始化参数
   */
  slides: Slide[]
}


/**
 * PPTArchiveInfo，ppt归档数据
 */
export interface ArchiveInfo {
  /**
   * 文件名称
   */
  name: string;
  /**
   * 文件数据
   */
  entity: ArchiveInfoEntity
}


/**
 * 缩略图数据
 */
export interface PageSnapshootsResult {
  pageId?: string,
  dataURL?: string
}


/**
 * 插入图片请求收到的响应
 */
export class InsertImgResponse {
  constructor(
    public url: string,
    /**
     * 这里需要的传入文件的原始类型，
     * @see {MIME_TYPES}
     */
    public mimeType: string,
    public name: string
  ) {

  }
}
