/**
 * 编辑器当前所在的上下文环境
 */
export enum RunningContext {
    /**
     * 前端客户端
     */
    front = 1,
    /**
     * 官网
     */
    open = 2,
}
