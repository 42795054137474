/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import { EMPTY, Observable, Subject, Subscription } from 'rxjs'
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators'
import { globalData } from '@/provide/global-contract'
import { JkIframePostman, ObjectUtils } from 'jkf-pure-commons'
import { ArchiveInfoEntity, EmbedEditorAction, EmbedEditorMsgData, ArchiveInfo, InsertImgResponse } from './archive-contract'
import { _pptLocalStorageService } from '@/data/archive/local-storage'
import { _iframeDriver } from '@/data/archive/iframe-driver'
import { _pptIframeStorageService } from './iframe-storage'
import { Slide } from '@/types/slides'
import useSaveData from '@/hooks/useSave'
import { _exportImageService } from './export-image'
import { _urlParamsResolver } from '../url-params/url-params.resolver'
import { RunningContext } from '../url-params/contract'
import { useMainStore } from '@/store'

/**
 * ppt归档助手
 */
export class PptArchiveHelper {

  private destroy$ = new Subject<void>()

  /**
   * 自动保存Subject
   */
  private autosaveSubject = new Subject<void>()

  constructor() {
    this.startupAutosaveMechanism()
    this.onListenPageSnapshootsAction()
    this.onListenExportAsAction()
  }

  /**
   * 启用自动保存机制
   */
  private startupAutosaveMechanism() {
    // TAG:当context等于front时，启用自动保存机制
    if (_urlParamsResolver.context === RunningContext.front) {
      this.autosaveSubject.pipe(
        takeUntil(this.destroy$),
        debounceTime(1500),
        tap(
          () => {
            console.warn('savePPTInfoToArchive')
            this.savePPTInfoToArchive({
              slides: this.normalSildes
            })
          }
        )
      ).subscribe()

      setTimeout(() => {
        // // TAG:监听Slides[]的数据变化以触发自动存储
        useSaveData().subscribeSlidesChanges((mutation, state) => {
          // console.log('subscribeSlidesChanges:', mutation, state)
          this.autosaveSubject.next()
        })
      }, 2000)
    }

  }


  get name(): string {
    return globalData.name
  }

  /**
   * 获取一个标准的Slide[]
   */
  get normalSildes(): Slide[] {
    // TAG:从`useSaveData()`中获取Slide[]
    const _slides = useSaveData().getSlides() as any[]
    const _result: Slide[] = _slides?.map(
      s => {
        // FIXME:使用`Object.assign({}, s)`进行对象clone无法更改slide的原型链，因此使用`ObjectUtils.objectDeepCopy`进行clone以更改其原型链
        const obj: Slide = ObjectUtils.objectDeepCopy({}, s) as Slide
        return obj
      }
    )
    console.log('normalSildes:', _result)
    return _result
  }

  /**
   * 选择文件
   */
  onSelectFile() {
    const msgObj: EmbedEditorMsgData = {
      action: EmbedEditorAction._select
    }
    _iframeDriver.postMessageToParent(msgObj)
  }

  /**
   * 保存文件
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSave() {
    this.savePPTInfoToArchive({
      slides: this.normalSildes
    }, false)
  }

  /**
   * 退出编辑器
   */
  onExit() {
    const msgObj: EmbedEditorMsgData = {
      action: EmbedEditorAction._exit
    }
    _iframeDriver.postMessageToParent(msgObj)
  }

  /**
   * 保存并退出
   */
  onSaveAndExit() {
    const msgObj: EmbedEditorMsgData = {
      action: EmbedEditorAction._saveExit,
      content: { slides: this.normalSildes }
    }
    _iframeDriver.postMessageToParent(msgObj)
  }


  /**
   * 重命名
   */
  onRename(_newName: string) {
    globalData.name = _newName
    const msgObj: EmbedEditorMsgData = {
      action: EmbedEditorAction._rename,
      name: this.name
    }
    _iframeDriver.postMessageToParent(msgObj)
  }


  /**
   * 从存储中获取PPTArchiveInfo
   * @returns 
   */
  getPPTInfoFromArchive(): Observable<ArchiveInfo> {
    let obs: Observable<ArchiveInfo>
    if (JkIframePostman.isRunInIframe()) {
      // TAG:在iframe环境中运行时获取PPTArchiveInfo
      obs = _pptIframeStorageService.getPPTArchiveInfo()
    }
    else {
      // TAG:独立运行时获取PPTArchiveInfo
      obs = _pptLocalStorageService.getPPTArchiveInfo()
    }
    return obs
  }

  /**
   * 保存PPTArchiveInfo到存储中
   * @returns 
   */
  savePPTInfoToArchive(_entity: ArchiveInfoEntity, _autosave = true) {
    if (JkIframePostman.isRunInIframe()) {
      // TAG:在iframe环境中运行时保存PPTMapInitParams[]
      _pptIframeStorageService.savePPTArchiveInfo(_entity, _autosave)
    }
    else {
      // TAG:独立运行时保存PPTMap
      _pptLocalStorageService.savePPTArchiveInfo({
        name: this.name,
        entity: _entity
      })
    }
  }

  /**
   * 发起插入图片请求，在iframe环境中运行时有意义
   */
  onRequestInsertImg(): Observable<InsertImgResponse> {
    console.log('onRequestInsertImg.')
    if (!JkIframePostman.isRunInIframe()) {
      // TAG:在非iframe环境中运行时返回
      return EMPTY
    }
    const msgObj: EmbedEditorMsgData = {
      action: EmbedEditorAction._insertImgRequest
    }
    _iframeDriver.postMessageToParent(msgObj)
    return _pptIframeStorageService.getInsertImgResponse()
  }


  /**
   * 监听‘生成快照’命令并执行相关的操作，在iframe环境中运行时有意义
   */
  onListenPageSnapshootsAction() {
    if (!JkIframePostman.isRunInIframe()) {
      // TAG:在非iframe环境中运行时返回
      return
    }
    _exportImageService.exportResultGenerated
      .pipe(
        takeUntil(this.destroy$),
        tap((_resultList) => {
          console.log('exportResultGenerated:', _resultList)
          const msgObj: EmbedEditorMsgData = {
            action: EmbedEditorAction._pageSnapshootsResponse,
            content: _resultList
          }
          _iframeDriver.postMessageToParent(msgObj)
        })
      ).subscribe()
    _iframeDriver.receivedMsgSubject
      .pipe(
        takeUntil(this.destroy$),
        filter((_msgData: EmbedEditorMsgData) => {
          return _msgData?.action === EmbedEditorAction.pageSnapshootsRequest
        }),
        tap(
          _ => {
            _exportImageService.executeExportPageSnapshoots()
          }
        )
      ).subscribe()
  }

  /**
   * 监听‘导出为’命令并执行相关的操作，在iframe环境中运行时有意义
   */
  onListenExportAsAction() {
    if (!JkIframePostman.isRunInIframe()) {
      // TAG:在非iframe环境中运行时返回空的可订阅者
      return
    }
    _iframeDriver.receivedMsgSubject
      .pipe(
        takeUntil(this.destroy$),
        filter((_msgData: EmbedEditorMsgData) => {
          return _msgData?.action === EmbedEditorAction.exportAs
        }),
        tap(
          _msgData => {
            // console.log('onListenExportAsAction:', _msgData)
            useMainStore().setDialogForExport('pptx')
          }
        )
      ).subscribe()
  }

  /**
   * 将iframe移动到最高层
   */
  onIframeTopLayer() {
    if (!_urlParamsResolver.viewModeEnabled
      && _urlParamsResolver.isIntegratedLayout) {
      const msgObj = {
        action: EmbedEditorAction._iframeTopLayer
      }
      _iframeDriver.postMessageToParent(msgObj)
    }
  }

  /**
   * 将iframe移动到最底层
   */
  onIframeLowLayer() {
    if (!_urlParamsResolver.viewModeEnabled
      && _urlParamsResolver.isIntegratedLayout) {
      const msgObj = {
        action: EmbedEditorAction._iframeLowLayer
      }
      _iframeDriver.postMessageToParent(msgObj)
    }
  }


  /**
   * 释放所有资源
   */
  releaseAll() {
    this.destroy$.next()
    this.destroy$.complete()
  }

}

export const _pptArchiveHelper = new PptArchiveHelper()

