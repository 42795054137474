import { ByteUtils, FileUtils, JkIntactMimeType, ObjectUtils } from "jkf-pure-commons"
import { Observable, of, pipe } from "rxjs"
import { tap } from "rxjs/operators"
import { Modal } from 'ant-design-vue'

export class ImageUtils {

    /**
     * 获取图片的DataURL，如果体积是否超过最大值，如果超过最大值，则提示用户进行压缩
     * @param { Blob } _imageFile 图片文件
     * @param { number } _maxSize 单位为B 
     * @param _confirmHandler 二次确认处理闭包
     */
    static getOrAdjustImageFileDataURL(_imageFile: Blob, _maxSize: number, _confirmHandler?: (_maxSizeDescribe: string) => Observable<boolean>): Observable<string> {
        if (_imageFile && ObjectUtils.isNotNull(_maxSize)) {
            return new Observable(
                _emitter => {
                    FileUtils.readFileAsDataURL(_imageFile, (_dataURL) => {
                        // console.log('readFileAsDataURL:', _imageFile, _dataURL.length);
                        if (_dataURL.length > _maxSize) {

                            const _handler = _confirmHandler ? _confirmHandler : (_maxSizeDescribe: string) => of(true)
                            const $sub = _handler(`${ByteUtils.convertByteToKB(_maxSize, 2)}KB`).pipe(
                                tap(
                                    _allowed => {
                                        if (_allowed) {
                                            FileUtils.compressImgDataURL(
                                                _dataURL,
                                                _maxSize,
                                                JkIntactMimeType.image_jpeg,
                                                false
                                            ).pipe(
                                                tap(
                                                    _result => {
                                                        console.log(`compressImgDataURL,result:' ${ByteUtils.convertByteToKB(_result?.length)}KB`, ByteUtils.smartConvertUnit(_result?.length))
                                                        _emitter.next(_result)
                                                        _emitter.complete()
                                                    }
                                                )
                                            ).subscribe()
                                        } else {
                                            _emitter.next(null as any)
                                            _emitter.complete()
                                        }
                                    }
                                )
                            ).subscribe(
                                () => {
                                    $sub?.unsubscribe()
                                },
                                (_err) => {
                                    $sub?.unsubscribe()
                                }
                            )


                            // BUG:使用该方式将导致对话框无法关闭，可能是`ant-design-vue`与vue的版本不兼容导致的
                            // const modalRef = Modal.confirm({
                            //     title: "温馨提示",
                            //     content: 图片体积超过最大限制(${ByteUtils.convertByteToKB(_maxSize, 2)}KB)，自动调整图片大小后载入?,
                            //     type: 'warn',
                            //     closable: true,
                            //     maskClosable: true,
                            //     mask: false,
                            //     okText: "确认",
                            //     cancelText: "取消",
                            //     zIndex: 2000,
                            //     onOk: () => {
                            //         console.error('onOk')
                            //         Modal.destroyAll()
                            //         modalRef.destroy()
                            //         FileUtils.compressImgDataURL(
                            //             _dataURL,
                            //             _maxSize,
                            //             JkIntactMimeType.image_jpeg,
                            //             false
                            //         ).pipe(
                            //             tap(
                            //                 _result => {
                            //                     console.log(`compressImgDataURL,result:' ${ByteUtils.convertByteToKB(_result?.length)}KB`, ByteUtils.smartConvertUnit(_result?.length))
                            //                     _emitter.next(_result)
                            //                     _emitter.complete()
                            //                 }
                            //             )
                            //         ).subscribe()

                            //         return Promise.resolve()
                            //     },
                            //     onCancel: () => {
                            //         console.error('onCancel')
                            //         Modal.destroyAll()
                            //         modalRef.destroy()
                            //         _emitter.next(null as any)
                            //         _emitter.complete()
                            //         return Promise.resolve()
                            //     },
                            //     afterClose: () => {
                            //         console.error('afterClose')
                            //     }
                            // }

                            //     //     `图片体积超过最大限制(${ByteUtils.convertByteToKB(_maxSize, 2)}KB)，自动调整图片大小后载入?`, "温馨提示", {

                            //     //     confirmButtonText: "确认",
                            //     //     cancelButtonText: "取消",
                            //     //     type: "warning",
                            //     // }


                            // )
                        } else {
                            console.warn(`assertThenAdjustImageSize,_dataURL.length is not beyond max size,_dataURL.length=${_dataURL.length},_maxSize=${_maxSize}`)
                            _emitter.next(_dataURL)
                            _emitter.complete()
                        }
                    }, (error) => {
                        _emitter.error(error)
                    })
                }
            )
        } else {
            console.warn('assertThenAdjustImageSize,but input params is invalid.')
            return of(null as any)
        }
    }


}