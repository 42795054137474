/* eslint-disable no-console */
import { JkIframeDriverBase } from 'jkf-pure-commons'
import { Observable, Subject } from 'rxjs'
import { EmbedEditorMsgData } from './archive-contract'

export class IframeDriver extends JkIframeDriverBase<EmbedEditorMsgData, EmbedEditorMsgData> {

  private _receivedMsgSubject = new Subject<EmbedEditorMsgData>();

  /**
   * 收到消息后发出事件
   */
  get receivedMsgSubject(): Observable<EmbedEditorMsgData> {
    return this._receivedMsgSubject
  }

  constructor() {
    super()
  }

  /**
   * 当在iframe环境中加载时，接收来自父级window的消息
   * @returns
   */
  handleMessage = (_event: MessageEvent<EmbedEditorMsgData>) => {
    console.log('handleMessage:', _event.data)
    if (_event && _event.data?.action) {
      // const _action = _event.data?.action
      // if (_action === EmbedEditorAction.load) {
      //   // console.warn('load content:', _event.data?.content);
      //   const info:ArchiveInfo = {
      //     entity: _event.data?.content,
      //     name: _event.data?.name
      //   } as any
      //   this._loadedSubject.next(info)
      // }
      this._receivedMsgSubject.next(_event.data)
    }
    else {
      // console.warn('_event is invaild.')
    }
  }


}

export const _iframeDriver = new IframeDriver()



