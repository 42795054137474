/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import { Observable, Subject } from 'rxjs'
import { mergeMap, takeUntil, tap, map } from 'rxjs/operators'
import { globalData } from '@/provide/global-contract'
import { CollectionUtils } from 'jkf-pure-commons'
import { _pptLocalStorageService } from '@/data/archive/local-storage'
import { _iframeDriver } from '@/data/archive/iframe-driver'
import { _pptIframeStorageService } from './archive/iframe-storage'
import { _pptArchiveHelper } from './archive/archive-helper'
import { slides as mockSlides } from '@/mocks/slides'
import useSaveData from '@/hooks/useSave'
import { fontFamilyManager } from './fonts/font-family-manager'



/**
 * ppt管理者
 */
export class PptMaster {

  private destroy$ = new Subject<void>()

  constructor() { }

  /**
   * 初始化PPT
   * @returns
   */
  initPPT(): Observable<any> {
    this.releaseAll()
    // TAG:从存储中获取ppt数据
    const pptInfoObs = _pptArchiveHelper.getPPTInfoFromArchive()
    // TAG:获取即刻易用提供的网络字体资源
    const fontObs = fontFamilyManager.requestJkSystemFontSources()
    return pptInfoObs.pipe(
      takeUntil(this.destroy$),
      mergeMap(
        _info => {
          return fontObs.pipe(
            map(_ => _info)
          )
        }
      ),
      tap(
        _info => {
          console.warn('initPPT:', _info)
          globalData.name = _info?.name || '未命名'
          if (CollectionUtils.validList(_info?.entity?.slides)) {
            useSaveData().setSlides(_info.entity.slides)
          }
          else {
            console.warn('_info.entity.slides is invalid,use mock slides.')
            useSaveData().setSlides(mockSlides)
          }
        }
      )
    )
  }

  /**
   * 释放所有资源
   */
  releaseAll() {
    this.destroy$.next()
    this.destroy$.complete()
  }

}

export const _pptMaster = new PptMaster()