/* eslint-disable @typescript-eslint/no-empty-function */
import { ObjectUtils, StringUtils } from 'jkf-pure-commons'
import { Observable, of } from 'rxjs'
import { ArchiveInfo } from './archive-contract'
import { message } from 'ant-design-vue'
import { JkzMessageUtils } from '@/commons/message'


export class PptLocalStorageService {

  private pptArchiveInfoStoreKey = 'jk-ppt-archive-info';

  constructor() { }

  /**
   * 独立运行时，获取PPTArchiveInfo
   */
  getPPTArchiveInfo(): Observable<ArchiveInfo> {
    const _jsonString = localStorage.getItem(this.pptArchiveInfoStoreKey) as string
    if (StringUtils.isNotNullOrEmpty(_jsonString)) {
      return of(JSON.parse(_jsonString))
    }
    return of(null as any)
  }

  /**
   * 保存PPTArchiveInfo
   * @param _info
   */
  savePPTArchiveInfo(_info: ArchiveInfo) {
    if (!ObjectUtils.ifObjectEmpty(_info)) {
      const _jsonString = JSON.stringify(_info)
      localStorage.setItem(this.pptArchiveInfoStoreKey, _jsonString)
    }
    else {
      this.removePPTJson()
    }
    JkzMessageUtils.throttleSuccess('保存成功')
  }

  /**
   * 删除PPTMapInitParams
   */
  removePPTJson() {
    localStorage.removeItem(this.pptArchiveInfoStoreKey)
  }

}

export const _pptLocalStorageService = new PptLocalStorageService()