/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import { EmbedEditorAction, EmbedEditorMsgData, ArchiveInfo, ArchiveInfoEntity, InsertImgResponse } from './archive-contract'
import { _iframeDriver } from './iframe-driver'
import { Observable, Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'


export class PptIframeStorageService {

  constructor() { }


  /**
   * 在iframe环境中，获取PPTArchiveInfo
   */
  getPPTArchiveInfo(): Observable<ArchiveInfo> {
    // TAG:在iframe环境中运行时初始化pptMap
    _iframeDriver.registerMessageEvent()
    _iframeDriver.postMessageToParent({ action: EmbedEditorAction._init })
    return _iframeDriver.receivedMsgSubject
      .pipe(
        filter((_msgData: EmbedEditorMsgData) => {
          return _msgData?.action === EmbedEditorAction.load
        }),
        map(_msgData => {
          console.warn('load content:', _msgData?.content)
          const info: ArchiveInfo = {
            name: _msgData?.name as any,
            entity: _msgData?.content as any
          }
          return info
        })
      )
  }

  /**
   * 在iframe环境中，保存PPTArchiveInfo
   * @param _entity
   * @param _autosave
   */
  savePPTArchiveInfo(_entity: ArchiveInfoEntity, _autosave: boolean) {
    const _action = _autosave ? EmbedEditorAction._autosave : EmbedEditorAction._save
    const msgObj: EmbedEditorMsgData = {
      action: _action,
      content: _entity
    }
    _iframeDriver.postMessageToParent(msgObj)
  }

  /**
   * 如果运行在iframe环境中则进行相关的处理
   */
  getInsertImgResponse(): Observable<InsertImgResponse> {
    return _iframeDriver.receivedMsgSubject
      .pipe(
        filter((_msgData: EmbedEditorMsgData) => {
          return _msgData?.action === EmbedEditorAction.insertImgResponse
        }),
        map(_msgData => {
          console.warn('insert img response:', _msgData?.content)
          return _msgData?.content as any
        })
      )
  }


  /**
   * 释放所有资源
   */
  releaseAll() {
    _iframeDriver.unregisterMessageEvent()
  }


}

export const _pptIframeStorageService = new PptIframeStorageService()